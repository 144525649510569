import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import DatetimePicker from 'vuetify-datetime-picker';
import VueSwal from 'vue-swal';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import "./registerServiceWorker";



Vue.use(VueSkycons, {
  color: "#1e88e5",
});

Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(DatetimePicker);
Vue.use(require('vue-moment'));
Vue.use(VueSwal);

store.dispatch("obtenerUsuario");


new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
