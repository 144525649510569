/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "La aplicación está siendo servida desde la caché por un service worker."
      );
    },
    registered() {
      console.log("Service worker ha sido registrado.");
    },
    cached() {
      console.log("El contenido se ha almacenado en la caché para su uso sin conexión.");
    },
    updatefound() {
      console.log("Se descargan nuevos contenidos.");
    },
    updated() {
      console.log("Hay nuevos contenidos disponibles; actualícelos.");
    },
    offline() {
      console.log(
        "No se ha encontrado conexión a Internet. La aplicación se ejecuta en modo offline."
      );
    },
    error(error) {
      console.error("Error durante el registro del service worker:", error);
    },
  });
}
