<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <div class="text-center">
      <v-snackbar v-model="onLine" :timeout="timeout" color="green">
        Tienes conexión
      </v-snackbar>
    </div>
    <div class="text-center">
      <v-snackbar v-model="offLine" :timeout="-1" color="orange">
        No tienes conexión
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="offLine = false">
            Ok!
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      modeNavigator: false,
      modeOnline: false,
      onLine: false,
      offLine: false,
      timeout: 2000,
      tiempo: 0
    };
  },
  created() {
    window.addEventListener("online", this.isOnline);
    window.addEventListener("offline", this.isOnline);
    this.isOnline();
  },
  methods: {
    isOnline() {
      if (navigator.onLine) {
        // tenemos conexion 
        console.log("tengo conexion")
        this.onLine = true; 
        this.$store.commit('setOnlineState', true);
      } else {
        // no tenemos conexion 
        console.log(" No tengo conexion")
        this.offLine = true; 
        this.$store.commit('setOnlineState', false);
      }
    },
  },
};
</script>