<template>

  <BaseItemGroup
    :item="item"
    text
    :prepend-icon="item.icon"
    sub-group
    append-icon="mdi-menu-down"
    class="xyz"
    v-if="verItems"
  />
</template>

<script>
  export default {
    name: 'BaseItemSubGroup',

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined, 
          title: undefined,
          children: [],
          permiso: undefined
        }),
      },
    },
    data() {
      return {
        menu: JSON.parse(localStorage.getItem("menu")),
        roles: localStorage.getItem("userRoles"),
      }
    },
    computed: {
      verItems() {
        if (this.roles.includes("superAdmin"))
        return true;

        for (let i = 0; i < this.menu.length; i++) {
          if (this.menu[i] == this.item.permiso) {
            return true;
          }
        }
        return false;
      },
    }
  }
</script>
