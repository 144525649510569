<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="``"
    v-if="verItems"
  >
   
    <v-list-item-icon v-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>
    

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'

  export default {
    name: 'BaseItem',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
           href: undefined,
          icon: undefined,
          title: undefined,
          to: undefined,
          permiso: undefined
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        menu: JSON.parse(localStorage.getItem("menu")),
        roles: localStorage.getItem("userRoles"),
      }
    },

    computed: {
      verItems() {
        if (this.roles.includes("superAdmin"))
        return true;

        for (let i = 0; i < this.menu.length; i++) {
          if (this.menu[i] == this.item.permiso) {
            return true;
          }
        }
        return false;
      },
        
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      },
    },
  }
</script>
