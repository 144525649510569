import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from "../store/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/home",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Home",
          path: "/",
          component: () => import("@/views/StarterPage"),
          meta: { rutaProtegida: true }
        },
      ],
    },
    {
      path: "/ajustes",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Perfil",
          path: "perfil",
          component: () => import("@/views/Perfil"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Roles",
          path: "roles",
          component: () => import("@/views/Roles/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "CrearRoles",
          path: "roles/crear",
          component: () => import("@/views/Roles/Crear.vue"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarRoles",
          path: "roles/editar/:id",
          component: () => import("@/views/Roles/Editar.vue"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Colaboradores",
          path: "colaboradores",
          component: () => import("@/views/Colaboradores/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarColaboradores",
          path: "colaboradores/editar/:id",
          component: () => import("@/views/Colaboradores/Editar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ListadoColaborador",
          path: "colaboradores/listados",
          component: () => import("@/views/Catalogos/Listados/Colaborador/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarListadoColaborador",
          path: "colaboradores/listados/edit/:id",
          component: () => import("@/views/Catalogos/Listados/Colaborador/Items"),
          meta: { rutaProtegida: true }
        },
      ],
    },
    {
      path: "/pastoreos",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Pastoreos",
          path: "listado",
          component: () => import("@/views/Pastoreos/ListadoPastoreos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "CrearPastoreos",
          path: "create",
          component: () => import("@/views/Pastoreos/CrearPastoreos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "CompletarPastoreos",
          path: "pendientes/completar/:id",
          component: () => import("@/views/Pastoreos/CompletarPastoreos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "PendientesPastoreos",
          path: "pendientes",
          component: () => import("@/views/Pastoreos/PendientesPastoreos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarPastoreos",
          path: "pendientes/editar/:id",
          component: () => import("@/views/Pastoreos/EditarPastoreos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ImportarPastoreos",
          path: "import",
          component: () => import("@/views/Pastoreos/ImportarPastoreos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Potreros",
          path: "potreros",
          component: () => import("@/views/Pastoreos/Maestros/Potreros"),
          meta: { rutaProtegida: true }
        },
        {
          name: "TablaPastoreos",
          path: "tablapastoreo",
          component: () => import("@/views/Pastoreos/Maestros/TablaPastoreo"),
          meta: { rutaProtegida: true }
        },
        {
          name: "VariedadPastos",
          path: "variedadpasto",
          component: () => import("@/views/Pastoreos/Maestros/VariedadPastos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Tramos",
          path: "tramos",
          component: () => import("@/views/Pastoreos/Maestros/Tramos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Lotes",
          path: "lotes",
          component: () => import("@/views/Pastoreos/Maestros/Lotes"),
          meta: { rutaProtegida: true }
        },
      ],
    },
    {
      path: "/pesajebascula",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "ListadoPesajes",
          path: "listado",
          component: () => import("@/views/Pesajes/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "PrimerPesaje",
          path: "primerpesaje",
          component: () => import("@/views/Pesajes/PrimerPesaje"),
          meta: { rutaProtegida: true }
        },
        {
          name: "PesajesPendientes",
          path: "pendientes",
          component: () => import("@/views/Pesajes/Pendientes"),
          meta: { rutaProtegida: true }
        },
        {
          name: "SegundoPesaje",
          path: "pendientes/segundopesaje/:id",
          component: () => import("@/views/Pesajes/SegundoPesaje"),
          meta: { rutaProtegida: true }
        },
        {
          name: "PesajesFinalizados",
          path: "finalizados",
          component: () => import("@/views/Pesajes/Finalizados"),
          meta: { rutaProtegida: true }
        },
        {
          name: "PesajesAnulados",
          path: "anulados",
          component: () => import("@/views/Pesajes/Anulados"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ImportarPesajes",
          path: "import",
          component: () => import("@/views/Pesajes/Importar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarPesaje",
          path: "listado/editar/:id",
          component: () => import("@/views/Pesajes/Editar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Materiales",
          path: "materiales",
          component: () => import("@/views/Pesajes/Maestros/Materiales"),
          meta: { rutaProtegida: true }
        },
      ]
    },
    {
      path: "/consumodieselcalderas",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "ListadoConsumos",
          path: "listado",
          component: () => import("@/views/ConsumoDiesel/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "CrearConsumos",
          path: "create",
          component: () => import("@/views/ConsumoDiesel/Crear"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarConsumos",
          path: "listado/edit/:id",
          component: () => import("@/views/ConsumoDiesel/Editar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ImportarConsumos",
          path: "import",
          component: () => import("@/views/ConsumoDiesel/Importar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ReporteConsumos",
          path: "reportes",
          component: () => import("@/views/ConsumoDiesel/Reportes"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ReporteDiesel",
          path: "reportes/diesel",
          component: () => import("@/views/ConsumoDiesel/Reportes/Diesel"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ReporteAgua",
          path: "reportes/agua",
          component: () => import("@/views/ConsumoDiesel/Reportes/Agua"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ReporteMensual",
          path: "reportes/mensual",
          component: () => import("@/views/ConsumoDiesel/Reportes/Mensual"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ReporteProyeccion",
          path: "reportes/proyeccion",
          component: () => import("@/views/ConsumoDiesel/Reportes/Proyeccion"),
          meta: { rutaProtegida: true }
        },
      ]
    },
    {
      path: "/login",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "/",
          component: () => import("@/views/authentication/FullLogin"),
        }
      ],
    },
    {
      path: "/forgot-password",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "ForgotPassword",
          path: "/",
          component: () => import("@/views/authentication/ForgotPassword"),
        }
      ],
    },
    {
      path: "/reset-password",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "ResetPassword",
          path: "/",
          component: () => import("@/views/authentication/ResetPassword"),
        }
      ],
    },
    {
      path: "/error",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Error404",
          path: "404",
          component: () => import("@/views/authentication/Error404"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Error403",
          path: "403",
          component: () => import("@/views/authentication/Error403"),
          meta: { rutaProtegida: true }
        },
      ],
    },
    {
      path: "*",
      redirect: "/home"
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/catalogos",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "RegionMayor",
          path: "region-mayor",
          component: () => import("@/views/Catalogos/RegionMayor"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Region",
          path: "region",
          component: () => import("@/views/Catalogos/Region"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ListadoRegion",
          path: "region/listados",
          component: () => import("@/views/Catalogos/Listados/Region/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarListadoRegion",
          path: "region/listados/edit/:id",
          component: () => import("@/views/Catalogos/Listados/Region/Items"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Rubro",
          path: "rubro",
          component: () => import("@/views/Catalogos/Rubro"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Clasificacion",
          path: "clasificacion",
          component: () => import("@/views/Catalogos/Clasificacion"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Trabajo",
          path: "trabajo",
          component: () => import("@/views/Catalogos/Trabajo"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ListadoTrabajo",
          path: "trabajo/listados",
          component: () => import("@/views/Catalogos/Listados/Trabajo/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarListadoTrabajo",
          path: "trabajo/listados/edit/:id",
          component: () => import("@/views/Catalogos/Listados/Trabajo/Items"),
          meta: { rutaProtegida: true }
        },
        {
          name: "CentroCostoDos",
          path: "centro-costo-dos",
          component: () => import("@/views/Catalogos/CentroCostoDos"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ListadoCentroCostoDos",
          path: "centro-costo-dos/listados",
          component: () => import("@/views/Catalogos/Listados/CC2/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarListadoCentroCostoDos",
          path: "centro-costo-dos/listados/edit/:id",
          component: () => import("@/views/Catalogos/Listados/CC2/Items"),
          meta: { rutaProtegida: true }
        },
        {
          name: "Cuadrilla",
          path: "cuadrilla",
          component: () => import("@/views/Catalogos/Cuadrilla"),
          meta: { rutaProtegida: true }
        },
      ]
    },
    {
      path: "/lluvia",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Pluviometro",
          path: "listado",
          component: () => import("@/views/Pluviometros/Listado"),
          meta: { rutaProtegida: true }
        },
        {
          name: "CrearPluviometro",
          path: "create",
          component: () => import("@/views/Pluviometros/Crear"),
          meta: { rutaProtegida: true }
        },
        {
          name: "CompletarPluviometro",
          path: "pendientes/completar/:id",
          component: () => import("@/views/Pluviometros/Completar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "PendientesPluviometro",
          path: "pendientes",
          component: () => import("@/views/Pluviometros/Pendientes"),
          meta: { rutaProtegida: true }
        },
        {
          name: "EditarPluviometro",
          path: "listado/editar/:id",
          component: () => import("@/views/Pluviometros/Editar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "ImportarPluviometro",
          path: "import",
          component: () => import("@/views/Pluviometros/Importar"),
          meta: { rutaProtegida: true }
        },
        {
          name: "RegionPluviometro",
          path: "regiones",
          component: () => import("@/views/Pluviometros/Maestros/Region"),
          meta: { rutaProtegida: true }
        }
      ],
    },
    {
      path: "/moduloservicios",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "OrdenesTrabajoAbiertas",
          path: "ordenes-abiertas",
          component: () => import("@/views/ModuloServicios/OrdenesTrabajoAbiertas"),
          meta: { rutaProtegida: true }
        },
        {
          name: "OrdenesTrabajoFinalizadas",
          path: "ordenes-finalizadas",
          component: () => import("@/views/ModuloServicios/OrdenesTrabajoFinalizadas"),
          meta: { rutaProtegida: true }
        },
        {
          name: "OrdenesTrabajo",
          path: "ordenes",
          component: () => import("@/views/ModuloServicios/OrdenesTrabajoFiltro"),
          meta: { rutaProtegida: true }
        },
        {
          name: "AsignadasElectrico",
          path: "asignadas-electrico",
          component: () => import("@/views/ModuloServicios/Asignadas01Electrico"),
          meta: { rutaProtegida: true }
        },
        {
          name: "AsignadasRefrigeracion",
          path: "asignadas-refrigeracion",
          component: () => import("@/views/ModuloServicios/Asignadas02Refrigeracion"),
          meta: { rutaProtegida: true }
        },
        {
          name: "AsignadasSoldadura",
          path: "asignadas-soldadura",
          component: () => import("@/views/ModuloServicios/Asignadas03Soldadura"),
          meta: { rutaProtegida: true }
        },
        {
          name: "AsignadasCarpinteria",
          path: "asignadas-carpinteria",
          component: () => import("@/views/ModuloServicios/Asignadas04Carpinteria"),
          meta: { rutaProtegida: true }
        },
        {
          name: "AsignadasMecanico",
          path: "asignadas-mecanico",
          component: () => import("@/views/ModuloServicios/Asignadas05Mecanico"),
          meta: { rutaProtegida: true }
        },
        {
          name: "AsignadasPintura",
          path: "asignadas-pintura",
          component: () => import("@/views/ModuloServicios/Asignadas06Pintura"),
          meta: { rutaProtegida: true }
        },
        {
          name: "OrdenesTrabajoDetalle",
          path: "ordenes/ver/:id",
          component: () => import("@/views/ModuloServicios/DetalleOrden"),
          meta: { rutaProtegida: true }
        },
        {
          name: "OrdenesTrabajoAsignar",
          path: "ordenes-abiertas/asignar/:id",
          component: () => import("@/views/ModuloServicios/Asignar"),
          meta: { rutaProtegida: true }
        },

      ],
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

function hasAccess(name) {
  let permissions = localStorage.getItem("userPermissions")
  let roles = localStorage.getItem("userRoles");

  switch (name) {
    case "Home":
      return true;

    case "Error404":
      return true;

    case "Error403":
      return true;

    case "Login":
      return true;

    case "ForgotPassword":
      return true;

    case "Perfil":
      return permissions.includes("ver_perfil") || roles.includes("superAdmin");

    /*
     * Rutas protegidas PASTOREO
     */
    case "Pastoreos":
      return permissions.includes("ver_pastoreo") || roles.includes("superAdmin");
    case "CrearPastoreos":
      return permissions.includes("crear_pastoreo") || roles.includes("superAdmin");
    case "CompletarPastoreos":
      return permissions.includes("finalizar_pastoreo") || roles.includes("superAdmin");
    case "PendientesPastoreos":
      return permissions.includes("ver_pastoreo") || roles.includes("superAdmin");
    case "EditarPastoreos":
      return permissions.includes("editar_pastoreo") || roles.includes("superAdmin");
    case "ImportarPastoreos":
      return permissions.includes("importar_pastoreo") || roles.includes("superAdmin");
    case "Potreros":
      return permissions.includes("ver_potreros") || roles.includes("superAdmin");
    case "TablaPastoreos":
      return permissions.includes("ver_tablapastoreos") || roles.includes("superAdmin");
    case "VariedadPastos":
      return permissions.includes("ver_variedadpastos") || roles.includes("superAdmin");
    case "Tramos":
      return permissions.includes("ver_tramos") || roles.includes("superAdmin");
    case "Lotes":
      return permissions.includes("ver_lotes") || roles.includes("superAdmin");

    /*
     * Rutas protegidas PESAJES
     */
    case "ListadoPesajes":
      return permissions.includes("ver_pesajes") || roles.includes("superAdmin");
    case "PrimerPesaje":
      return permissions.includes("crear_pesajes") || roles.includes("superAdmin");
    case "PesajesPendientes":
      return permissions.includes("ver_pesajes_pendientes") || roles.includes("superAdmin");
    case "SegundoPesaje":
      return permissions.includes("finalizar_pesajes") || roles.includes("superAdmin");
    case "PesajesFinalizados":
      return permissions.includes("ver_pesajes_finalizados") || roles.includes("superAdmin");
    case "PesajesAnulados":
      return permissions.includes("ver_pesajes_anulados") || roles.includes("superAdmin");
    case "ImportarPesajes":
      return permissions.includes("importar_pesajes") || roles.includes("superAdmin");
    case "EditarPesaje":
      return permissions.includes("editar_pesajes") || roles.includes("superAdmin");
    case "Materiales":
      return permissions.includes("ver_materiales") || roles.includes("superAdmin");
    /*
     * Rutas protegidas CONSUMOS
     */
    case "ListadoConsumos":
      return permissions.includes("ver_consumodieselcalderas") || roles.includes("superAdmin");
    case "CrearConsumos":
      return permissions.includes("crear_consumodieselcalderas") || roles.includes("superAdmin");
    case "EditarConsumos":
      return permissions.includes("editar_consumodieselcalderas") || roles.includes("superAdmin");
    case "ImportarConsumos":
      return permissions.includes("importar_consumodieselcalderas") || roles.includes("superAdmin");
    case "ReporteConsumos":
      return permissions.includes("ver_consumodieselcalderas") || roles.includes("superAdmin");
    case "ReporteDiesel":
      return permissions.includes("reporte_diesel") || roles.includes("superAdmin");
    case "ReporteAgua":
      return permissions.includes("reporte_agua") || roles.includes("superAdmin");
    case "ReporteMensual":
      return permissions.includes("reporte_mensual") || roles.includes("superAdmin");
    case "ReporteProyeccion":
      return permissions.includes("reporte_proyeccion") || roles.includes("superAdmin");

    /*
     * Rutas protegidas ROLES
     */
    case "Roles":
      return permissions.includes("ver_roles") || roles.includes("superAdmin");
    case "CrearRoles":
      return permissions.includes("crear_roles") || roles.includes("superAdmin");
    case "EditarRoles":
      return permissions.includes("editar_roles") || roles.includes("superAdmin");

    /**
     * Rutas protegidas COLABORADORES
     */
    case "Colaboradores":
      return permissions.includes("ver_colaboradores") || roles.includes("superAdmin");
    case "EditarColaboradores":
      return permissions.includes("editar_colaboradores") || roles.includes("superAdmin");
    case "ListadoColaborador":
      return permissions.includes("ver_listados_colaboradores") || roles.includes("superAdmin");
    case "EditarListadoColaborador":
      return permissions.includes("editar_listados_colaboradores") || roles.includes("superAdmin");

    /**
     * Rutas protegidas CATALOGOS AGRISMART
     */
    case "RegionMayor":
      return permissions.includes("ver_regiones_mayores") || roles.includes("superAdmin");
    case "ListadoRegion":
      return permissions.includes("ver_listados_regiones") || roles.includes("superAdmin");
    case "EditarListadoRegion":
      return permissions.includes("editar_listados_regiones") || roles.includes("superAdmin");
    case "Region":
      return permissions.includes("ver_regiones") || roles.includes("superAdmin");
    case "Rubro":
      return permissions.includes("ver_rubros") || roles.includes("superAdmin");
    case "Clasificacion":
      return permissions.includes("ver_clasificaciones") || roles.includes("superAdmin");
    case "Trabajo":
      return permissions.includes("ver_trabajos") || roles.includes("superAdmin");
    case "ListadoTrabajo":
      return permissions.includes("ver_listados_trabajos") || roles.includes("superAdmin");
    case "EditarListadoTrabajo":
      return permissions.includes("editar_listados_trabajos") || roles.includes("superAdmin");
    case "CentroCostoDos":
      return permissions.includes("ver_centro_costos_dos") || roles.includes("superAdmin");
    case "ListadoCentroCostoDos":
      return permissions.includes("ver_listados_cc2") || roles.includes("superAdmin");
    case "EditarListadoCentroCostoDos":
      return permissions.includes("editar_listados_cc2") || roles.includes("superAdmin");
    case "Cuadrilla":
      return permissions.includes("ver_cuadrillas") || roles.includes("superAdmin");

    /*
     * Rutas protegidas PLUVIOMETROS
     */
    case "Pluviometro":
      return permissions.includes("ver_pluviometros") || roles.includes("superAdmin");
    case "CrearPluviometro":
      return permissions.includes("crear_pluviometros") || roles.includes("superAdmin");
    case "CompletarPluviometro":
      return permissions.includes("finalizar_pluviometros") || roles.includes("superAdmin");
    case "PendientesPluviometro":
      return permissions.includes("ver_pluviometros") || roles.includes("superAdmin");
    case "EditarPluviometro":
      return permissions.includes("editar_pluviometros") || roles.includes("superAdmin");
    case "ImportarPluviometro":
      return permissions.includes("importar_pluviometros") || roles.includes("superAdmin");
    case "RegionPluviometro":
      return permissions.includes("ver_listados_pluviometro") || roles.includes("superAdmin");
    /*
     * Rutas protegidas MODULO SERVICIOS
     */
    case "OrdenesTrabajo":
      return permissions.includes("ver_ordenes_trabajo") || roles.includes("superAdmin");
    case "OrdenesTrabajoAbiertas":
      return permissions.includes("ver_ordenes_trabajo") || roles.includes("superAdmin");
    case "OrdenesTrabajoFinalizadas":
      return permissions.includes("ver_ordenes_trabajo") || roles.includes("superAdmin");
    case "AsignadasElectrico":
      return permissions.includes("asignadas_taller_electrico") || roles.includes("superAdmin");
    case "AsignadasSoldadura":
      return permissions.includes("asignadas_taller_soldadura") || roles.includes("superAdmin");
    case "AsignadasPintura":
      return permissions.includes("asignadas_taller_pintura") || roles.includes("superAdmin");
    case "AsignadasRefrigeracion":
      return permissions.includes("asignadas_taller_refri") || roles.includes("superAdmin");
    case "AsignadasMecanico":
      return permissions.includes("asignadas_taller_mecanico") || roles.includes("superAdmin");
    case "AsignadasCarpinteria":
      return permissions.includes("asignadas_taller_carpinteria") || roles.includes("superAdmin");
    case "OrdenesTrabajoDetalle":
      return permissions.includes("ver_orden_trabajo_detalle") || roles.includes("superAdmin");
    case "OrdenesTrabajoAsignar":
      return permissions.includes("asignar_ordenes_trabajo") || roles.includes("superAdmin");
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.rutaProtegida) {
    if (store.getters.usuarioAutenticado) {
      if (hasAccess(to.name)) {
        next();
      } else {
        next('/error/403')
      }

    } else {
      next('/login');
    }
  } else {
    next();
  }
});



export default router;
