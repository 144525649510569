import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"; 
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios.defaults.headers.common['Authorization'] = `${access_token}` 
//axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}` 
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env["VUE_APP_API_URL"];

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    auth: false,
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: 'white',
    SidebarBg: '', 
    online: true, // Valor inicial
  },
  mutations: {
    SET_USUARIO(state, user) {
      state.user = user;
      state.auth = Boolean(user);
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload
    },
    setOnlineState(state, isOnline) {
      state.online = isOnline;
    },
  },
  actions: {
    async logout({ dispatch }) {
      await axios.post("/logout");
      localStorage.removeItem('user');
      localStorage.removeItem('userPermissions');
      localStorage.removeItem('userRoles');
      localStorage.removeItem('userFoto');
      localStorage.removeItem('menu');
      return dispatch("obtenerUsuario");
    },
    async login({ dispatch }, credenciales) { 
      console.log("entro !!")
      await axios.get("/sanctum/csrf-cookie");
      const res = await axios.post("/login", credenciales);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      localStorage.setItem("userPermissions", res.data.permisos);
      localStorage.setItem("menu", JSON.stringify(res.data.permisos));
      localStorage.setItem("userRoles", res.data.roles);
      localStorage.setItem("userFoto", res.data.fotoPerfil);
      return dispatch("obtenerUsuario");
    },
    obtenerUsuario({ commit }) {
      if (localStorage.getItem('user')) {
        commit('SET_USUARIO', JSON.parse(localStorage.getItem('user')))
      } else {

        axios
        .get("/api/user")
        .then((res) => {
          commit("SET_USUARIO", res.data.user);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem("userPermissions", res.data.permisos);
          localStorage.setItem("menu", JSON.stringify(res.data.permisos));
          localStorage.setItem("userRoles", res.data.roles);
          localStorage.setItem("userFoto", res.data.fotoPerfil);
        })
        .catch(() => {
          commit("SET_USUARIO", null);
        });
      }

    }
  },
  getters: {
    usuarioAutenticado(state) {
      return !!state.user
    }
  }
})