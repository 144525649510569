import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/scss/vuetify/overrides.scss';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const theme = {
    primary: '#FF9800', //Color de produccion: #1e88e5 || Color pruebas: #FF9800
    info: '#1e88e5',
    success: '#21c1d6',
    accent: '#fc4b6c',
    default: '#563dea'
}

export default new Vuetify({
  theme: {
      themes: {
        dark: theme,
        light: theme,
      },
    }
});
